<template>
  <div class="footer">
    I'm the footer.
  </div>
</template>

<style scoped>
.footer {
  bottom: 0;
  padding: 15px;
  margin-top: 100px;
}
</style>
