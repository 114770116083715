<template>
  <div class="homearea">
    <div class="text-holder">
      <h2>Découvrez nos recettes par zones géographiques</h2>
      <p>Lorem ipsum dolor sit amet</p>
      <router-link :to="{ name: 'Zones' }"><button class="btn-primary">Voir les Zones géographiques</button></router-link>
    </div>
    <div class="img-holder">
        <img id="categorie-cover" alt="vue area image" src="../assets/zonegeo.jpg">
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeArea'
}
</script>

<style scoped>
  .homearea {
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: center;
    justify-content: center;
  }
  .img-holder img {
    max-width: 100%;
  }
</style>
