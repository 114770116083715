<template>
  <div class="home">
    <img id="logo" alt="Vue logo" src="../assets/logo.png">
    <Header />
    <HomeCategorie />
    <HomeArea />
    <HomeAlpha />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import HomeCategorie from '@/components/HomeCategorie.vue'
import HomeArea from '@/components/HomeArea.vue'
import HomeAlpha from '@/components/HomeAlpha.vue'

export default {
  name: 'Home',
  components: {
    Header,
    HomeCategorie,
    HomeArea,
    HomeAlpha
  }
}
</script>

<style>
  #logo {
    max-height: 200px;
  }
</style>
