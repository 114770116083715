import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [{
  path: '/',
  name: 'Home',
  component: Home
},
{
  path: '/all',
  name: 'Alpha',
  component: () => import(/* webpackChunkName: "all" */ '../views/AllRecipes.vue'),
  children: [
    {
      path: ':lettre',
      name: 'Lettre',
      component: () => import(/* webpackChunkName: "lettre" */ '../components/Lettre.vue'),
      props: true
    }
  ]
},
{
  path: '/search',
  name: 'Search',
  component: () => import(/* webpackChunkName: "search" */ '../views/Search.vue')
},
{
  path: '/meal/:id',
  name: 'Meal',
  component: () => import(/* webpackChunkName: "categories" */ '../views/Meal.vue'),
  props: true
},
{
  path: '/categories',
  name: 'Categories',
  component: () => import(/* webpackChunkName: "categories" */ '../views/Categories.vue')
},
{
  path: '/categorie/:slug',
  name: 'Categorie',
  component: () => import(/* webpackChunkName: "categorie" */ '../views/Categorie.vue'),
  props: true
},
{
  path: '/zones',
  name: 'Zones',
  component: () => import(/* webpackChunkName: "zones" */ '../views/Zones.vue')
},
{
  path: '/zone/:slug',
  name: 'Zone',
  component: () => import(/* webpackChunkName: "zone" */ '../views/Zone.vue'),
  props: true
},
{
  path: '/ingredient/:slug',
  name: 'Ingredients',
  component: () => import(/* webpackChunkName: "ingredient" */ '../views/Ingredients.vue'),
  props: true
},
{
  path: '/404',
  alias: '/:catchAll(.*)*',
  component: () => import(/* webpackChunkName: "404" */ '../views/404.vue')
}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
