<template>
  <div class="hello">
    <h1>Bienvenue sur GretaEat</h1>
    <p>Retrouvez les recettes du monde entier, facilement et simplement grâce à Vue.js 3 et <a href="https://www.themealdb.com" target="_blank">TheMealDB</a> !</p>
  </div>
</template>

<script>
export default {
  name: 'Header'
}
</script>

<style scoped>
.hello {
  margin-bottom: 2.5rem;
}
</style>
