<template>
  <div id="nav">
    <router-link to="/"><font-awesome-icon icon="home" /></router-link> |
    <router-link :to="{ name: 'Alpha' }">A - Z</router-link> |
    <router-link :to="{ name: 'Categories' }">Catégories</router-link> |
    <router-link :to="{ name: 'Zones' }">Zones géographiques</router-link> |
    <router-link :to="{ name: 'Search' }">Rechercher</router-link>
  </div>
  <router-view />
  <Footer />
</template>

<script>
import Footer from '@/components/Footer.vue'

export default {
  name: 'App',
  components: {
    Footer
  },
  mounted () {
    fetch('https://www.themealdb.com/api/json/v1/1/list.php?a=list')
      .then(response => response.json())
      .then(response => {
        this.zones = response.meals
      })
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #333;
  background-color: white;
}
#nav {
  padding: 30px;
}
a {
  font-weight: bold;
  color: #A70E13;
  text-decoration: none;
}
.redtext {
  color: #A70E13;
}
#nav a.router-link-exact-active {
  color: #8B0304;
}
.header {
  max-width: 1200px;
  margin:auto;
}
.text-holder {
  padding: 5rem;
  text-align: left;
}
.text-holder p {
  padding-bottom: 1.5rem;
}
@media (max-width: 768px) {
  .text-holder {
    padding: 0rem;
    text-align: center;
    margin-bottom: 3rem;
  }
  .text-holder p {
    padding-bottom: 1.5rem;
  }
}
.btn-primary {
  background-color: #A70E13;
  color: white;
  padding: 20px;
  border-radius: 5px;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  border: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  cursor: pointer;
}
.btn-primary:hover {
  background-color: #8B0304;
  color: white;
}
.btn-secondary {
  color:white;
  padding: 10px;
  margin: 0 10px 5px 0;
  background-color: #A70E13;
  display:inline-block;
}
.btn-secondary:first-child {
  margin-left: 0px;
}
.btn-secondary:hover {
  background-color: #8B0304;
  color: white;
}
.card-container {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}
.card {
  padding: 15px;
  transition: transform .3s ease-out;
}
.card:hover {
  transform: translate(0, -10px);
}
</style>
